import styled from "styled-components";
import { AiFillDelete } from "react-icons/ai";
import { BiRename } from "react-icons/bi";
import {
  useDeleteUser,
  useGetUsers,
  useRenameUser,
} from "../../../api/useGetUsers";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Button } from "../../../components/Button";

const UserContainer = styled.div`
  display: flex;
  padding: 8px;
  justify-content: space-between;
`;

const UserActions = styled.div`
  display: flex;
  gap: 15px;

  > svg {
    width: 24px;
    height: 24px;
  }
`;

const Input = styled.input`
  width: 100%;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  padding: 8px;
  font-size: 18px;
  margin-right: 16px;
`;

export const User = ({ id, name }) => {
  const [isRenameUser, setRenameUser] = useState(false);
  const [renameValue, setRenameValue] = useState(name);
  const mutation = useDeleteUser();
  const renameUserMutation = useRenameUser();

  useEffect(() => {
    setRenameValue(name);
  }, [name]);

  const { refetch } = useGetUsers();
  const deleteUser = () => {
    if (
      window.confirm(
        "Вы действительно хотите удалить этого студента? При удалении студента он удалится из всех отрывков, где он участвует",
      )
    )
      mutation.mutate(id, {
        onSuccess: async (result) => {
          const json = await result.json();
          if (json.error)
            toast("Студент с таким именем уже есть!", { type: "error" });
          refetch();
        },
      });
  };

  const toggleRenameUser = () => {
    setRenameUser(true);
  };
  const renameUser = () => {
    setRenameUser(false);
    if (name !== renameValue)
      renameUserMutation.mutate(
        { id, name: renameValue },
        { onSuccess: () => refetch() },
      );
  };

  const updateName = (event) => {
    setRenameValue(event.target.value);
  };

  return (
    <UserContainer>
      {isRenameUser ? (
        <Input value={renameValue} onChange={updateName} />
      ) : (
        <p>{name}</p>
      )}
      <UserActions>
        {!isRenameUser && <AiFillDelete onClick={deleteUser} />}
        {!isRenameUser ? (
          <BiRename onClick={toggleRenameUser} />
        ) : (
          <Button onClick={renameUser} variant="green">
            Сохранить
          </Button>
        )}
      </UserActions>
    </UserContainer>
  );
};
