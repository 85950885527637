import { Button } from "../../../components/Button";
import { Select } from "antd";
import { useMemo, useState } from "react";
import { useSchedule } from "../../../api/schedule";
import { toast } from "react-toastify";
import { useMoveFragment } from "../../../api/fragments";

const findFreeTime = (data, day, auditory) => {
  if (!day) return;
  const newData = data; // Create a deep copy of the original data
  const foundDay = newData.find((d) => d.name === day);
  const foundAuditory = foundDay.auditories.find(
    (aud) => aud.name.toString() === auditory.toString(),
  );
  const emptyHours = [];
  for (let hour in foundAuditory.hours) {
    if (Object.keys(foundAuditory.hours[hour]).length === 0) {
      emptyHours.push(parseInt(hour));
    }
  }
  return emptyHours;
};

export const ChoseWhereMoving = ({ fragmentData, setIsMoving }) => {
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedAuditory, setSelectedAuditory] = useState(null);
  const { data: scheduleData, refetch: refetchScheduleData } = useSchedule();

  const onDayChange = (day) => {
    setSelectedDay(day);
  };

  const onTimeChange = (time) => {
    setSelectedTime(time);
  };

  const onAuditoryChange = (aud) => {
    setSelectedAuditory(aud);
  };

  const timeOptions = useMemo(() => {
    if (!selectedDay || !selectedAuditory) return [];

    return findFreeTime(scheduleData, selectedDay, selectedAuditory)?.map(
      (item) => ({
        value: item,
        label: `${item}:00`,
      }),
    );
  }, [scheduleData, selectedDay, selectedAuditory]);

  const mutation = useMoveFragment();

  const onSave = () => {
    if (!selectedDay || !selectedTime) {
      toast("Нужно заполнить день и время!", { type: "error" });
    } else {
      const data = {
        id: fragmentData.fragment.id,
        dayFrom: fragmentData.foundDay.name,
        auditoryFrom: fragmentData.auditory.name,
        timeFrom: fragmentData.time,
        dayTo: selectedDay,
        auditoryTo: selectedAuditory,
        timeTo: selectedTime,
      };
      mutation.mutate(data, {
        onSuccess: () => {
          setTimeout(() => {
            setIsMoving(false);
            refetchScheduleData();
          }, 1000);
        },
      });
    }
  };

  return (
    <div>
      <h1 style={{ marginBottom: "8px" }}>На какой день?</h1>
      <Select
        options={[
          { value: "Понедельник", label: "Понедельник" },
          { value: "Вторник", label: "Вторник" },
          { value: "Среда", label: "Среда" },
          { value: "Четверг", label: "Четверг" },
          { value: "Пятница", label: "Пятница" },
          { value: "Суббота", label: "Суббота" },
        ]}
        onChange={onDayChange}
        style={{ width: "100%" }}
        placeholder="Выберите день недели"
      />
      <h1 style={{ marginBottom: "8px", marginTop: "16px" }}>Аудитория</h1>
      <Select
        options={[
          { value: "413" },
          { value: "мастерская", label: "Мастерская" },
        ]}
        onChange={onAuditoryChange}
        style={{ width: "100%" }}
        disabled={!selectedDay}
        placeholder="Выберите аудиторию"
      />
      <h1 style={{ marginTop: "16px" }}>На какое время?</h1>
      <Select
        options={timeOptions}
        onChange={onTimeChange}
        style={{ width: "100%", marginBottom: "16px" }}
        disabled={!selectedDay || !selectedAuditory}
        placeholder="Выберите свободное время"
      />
      <Button onClick={onSave} variant="green" style={{ width: "100%" }}>
        Сохранить
      </Button>
    </div>
  );
};
