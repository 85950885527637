import styled from "styled-components";
import { Button } from "../../components/Button";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetFragments } from "../../api/fragments";
import { RotatingLines } from "react-loader-spinner";
import { Fragment } from "./parts/Fragment";
import { useGenerateSchedule, useSchedule } from "../../api/schedule";
import { AddFragmentModal } from "./parts/AddFragmentModal";
import { checkIsAdmin } from "../../utils/checkIsAdmin";
import { EditFragmentModal } from "./parts/EditFragmentModal";

const Wrapper = styled.div`
  padding: 10px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 20px;
`;

const FragmentsList = styled.div`
  max-height: calc(100dvh - 280px);
  overflow: auto;
`;

export const Fragments = () => {
  const [isAdd, setIsAdd] = useState(false);

  const { data, isLoading, refetch } = useGetFragments();

  const generateScheduleMutation = useGenerateSchedule();
  const navigate = useNavigate();

  const isAdmin = checkIsAdmin();

  if (!isAdmin) navigate("/");

  const { data: scheduleData, refetch: refetchScheduleData } = useSchedule();
  const onSuccessClick = async () => {
    await refetch();
    await refetchScheduleData();
    if (!scheduleData) {
      generateScheduleMutation.mutate(null, {
        onSuccess: () => {
          navigate("/");
        },
      });
    } else {
      navigate("/");
    }
  };

  const onModalClose = () => {
    setIsAdd(false);
    refetch();
  };
  const [fragmentId, setFragmentId] = useState(null);

  const onEditFragmentClose = () => {
    setFragmentId(null);
    refetch();
  };

  if (isLoading)
    return (
      <RotatingLines
        visible={true}
        height="96"
        width="96"
        color="grey"
        strokeWidth="5"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
      />
    );

  return (
    <>
      {isAdd && <AddFragmentModal onClose={onModalClose} />}
      {fragmentId && (
        <EditFragmentModal
          onClose={onEditFragmentClose}
          fragmentId={fragmentId}
        />
      )}
      <Wrapper>
        <h1 style={{ textAlign: "center", marginBottom: "8px" }}>
          Список отрывков:
        </h1>
        <FragmentsList>
          {data.map((user) => (
            <Fragment {...user} setFragmentId={setFragmentId} />
          ))}
        </FragmentsList>
        <Body>
          <Button onClick={() => setIsAdd(true)}>Добавить</Button>
          <Button
            variant="green"
            style={{ marginTop: "16px" }}
            onClick={onSuccessClick}
          >
            Готово
          </Button>
        </Body>
      </Wrapper>
    </>
  );
};
