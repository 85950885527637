import styled from "styled-components";
import { Button } from "../../components/Button";
import React, { useRef, useState } from "react";
import { useAddUser, useGetUsers } from "../../api/useGetUsers";
import { User } from "./parts/User";
import { useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";
import { checkIsAdmin } from "../../utils/checkIsAdmin";

const Wrapper = styled.div`
  padding: 10px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 20px;
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;

  > input {
    width: 100%;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 25px;
    padding: 8px;
    font-size: 18px;
  }
`;

const UsersContainer = styled.div`
  > div:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }

  max-height: calc(100dvh - 280px);
  overflow: auto;
  margin-bottom: 16px;
`;

export const Users = () => {
  const [isAdd, setIsAdd] = useState(false);
  const inputRef = useRef(null);

  const { data, refetch, isLoading } = useGetUsers();
  const mutation = useAddUser();
  const navigate = useNavigate();

  const isAdmin = checkIsAdmin();

  if (!isAdmin) navigate("/");

  const addUser = () => {
    setIsAdd(false);
    mutation.mutate(inputRef.current.value, {
      onSuccess: async (result) => {
        const json = await result.json();
        if (json.error)
          toast("Студент с таким именем уже есть!", { type: "error" });
        else refetch();
      },
    });
  };

  const onSuccessClick = () => {
    refetch();
    navigate("/fragments");
  };

  if (isLoading)
    return (
      <RotatingLines
        visible={true}
        height="96"
        width="96"
        color="grey"
        strokeWidth="5"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
      />
    );

  return (
    <Wrapper>
      <h1 style={{ textAlign: "center" }}>Список студентов:</h1>
      <Body>
        <UsersContainer>
          {data.map((user) => (
            <User {...user} />
          ))}
        </UsersContainer>
        {isAdd && (
          <InputContainer>
            <input ref={inputRef} />
            <Button onClick={addUser} variant="green">
              Сохранить
            </Button>
          </InputContainer>
        )}
        {!isAdd && <Button onClick={() => setIsAdd(true)}>Добавить</Button>}
        {!isAdd && data.length > 0 && (
          <Button
            variant="green"
            style={{ marginTop: "16px" }}
            onClick={onSuccessClick}
          >
            Готово
          </Button>
        )}
      </Body>
    </Wrapper>
  );
};
