import { Button } from "../../../components/Button";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useGetUsers } from "../../../api/useGetUsers";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  > button {
    width: 100%;
  }
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;

  > input {
    width: 100%;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 25px;
    padding: 8px;
    font-size: 18px;
  }
`;

const UsersContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  max-height: 100px;
  overflow: auto;

  > div:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
`;

const Student = styled.div`
  padding: 4px;
`;

const SearchInput = ({ addUser, selectedUsers, disableAddStudent }) => {
  const { data, isLoading } = useGetUsers();
  const [localUsers, setLocalUsers] = useState(
    data?.filter((st) => !selectedUsers.map((it) => it.id).includes(st.id)),
  );
  const inputRef = useRef(null);

  const onSearch = (e) => {
    const { value } = e.target;
    if (value === "")
      setLocalUsers(
        data?.filter((st) => !selectedUsers.map((it) => it.id).includes(st.id)),
      );
    else
      setLocalUsers(
        data
          ?.filter((st) => !selectedUsers.map((it) => it.id).includes(st.id))
          .filter((student) =>
            student.name.toLowerCase().includes(value.toLowerCase()),
          ),
      );
  };
  const onAddUser = (student) => {
    addUser(student);
    setLocalUsers((prev) => prev.filter((st) => st.id !== student.id));
    inputRef.current.value = "";
    setLocalUsers(
      data.filter((st) => !selectedUsers.map((it) => it.id).includes(st.id)),
    );
  };

  useEffect(() => {
    setLocalUsers(
      data?.filter((st) => !selectedUsers.map((it) => it.id).includes(st.id)),
    );
    // eslint-disable-next-line
  }, [isLoading]);

  return (
    <div>
      <InputContainer>
        <input
          onChange={onSearch}
          placeholder="Поиск по имени..."
          ref={inputRef}
        />
        <Button variant="green" onClick={disableAddStudent}>
          Закрыть
        </Button>
      </InputContainer>
      <UsersContainer>
        {localUsers?.length === 0 ? (
          <h1 style={{ padding: 5, textAlign: "center" }}>Ничего не найдено</h1>
        ) : (
          localUsers?.map((student) => (
            <Student onClick={() => onAddUser(student)}>{student.name}</Student>
          ))
        )}
      </UsersContainer>
    </div>
  );
};

export const AddStudentComponent = ({ addUser, selectedUsers }) => {
  const [isAddStudent, setIsAddStudent] = useState(false);

  const addStudentToFragment = () => {
    setIsAddStudent(true);
  };

  const disableAddStudent = () => {
    setIsAddStudent(false);
  };

  return (
    <Container>
      {isAddStudent && (
        <InputContainer>
          <SearchInput
            addUser={addUser}
            selectedUsers={selectedUsers}
            disableAddStudent={disableAddStudent}
          />
        </InputContainer>
      )}

      {!isAddStudent && (
        <Button onClick={addStudentToFragment}>
          Добавить студента к отрывку
        </Button>
      )}
    </Container>
  );
};
