import styled from "styled-components";
import { AiFillCloseCircle, AiFillDelete } from "react-icons/ai";
import { Checkbox } from "antd";
import { AddStudentComponent } from "./AddStudentComponent";
import React, { useRef, useState } from "react";
import {
  useDeleteFragment,
  useGetFragments,
  useUpdateFragment,
} from "../../../api/fragments";
import { useSchedule } from "../../../api/schedule";
import { Button } from "../../../components/Button";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  &.break-class-name {
    //your styles
  }
`;

const Container = styled.div`
  padding: 20px;
  background: white;
  border-radius: 16px;
  height: fit-content;
  width: calc(100% - 40px);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Body = styled.div``;

const CloseButton = styled.button`
  padding: 0;
  border: none;
  outline: none;
  background: none;
  width: 32px;
  height: 32px;
  position: relative;
  right: -15px;
  top: -15px;

  > svg {
    width: 32px;
    height: 32px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;

  > input {
    width: 100%;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 25px;
    padding: 8px;
    font-size: 18px;
  }
`;

const StudentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
`;

const Student = ({ name, id, removeStudent }) => {
  return (
    <StudentContainer>
      <h1>{name}</h1>
      <AiFillDelete onClick={() => removeStudent(id)} />
    </StudentContainer>
  );
};

const StudentsListContainer = styled.div`
  > div:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
`;

export const EditFragmentModal = ({ onClose, fragmentId }) => {
  const inputRef = useRef(null);
  const { data } = useGetFragments();
  const foundFragment = data.find((fragment) => fragment.id === fragmentId);

  const [fragmentName, setFragmentName] = useState(foundFragment.name);
  const [isChecked, setIsChecked] = useState(foundFragment.doNotCount);

  const [studentsToAddList, setStudentsToAddList] = useState(
    foundFragment?.students,
  );

  const addUserToList = (user) => {
    if (!studentsToAddList.find((st) => st.id === user.id))
      setStudentsToAddList((prev) => [...prev, user]);
  };

  const removeStudent = (id) => {
    setStudentsToAddList((prev) => prev.filter((st) => st.id !== id));
  };
  const mutation = useUpdateFragment();
  const mutationDelete = useDeleteFragment();
  const { refetch } = useSchedule();

  if (!foundFragment) return null;

  const updateFragment = () => {
    mutation.mutate(
      {
        id: fragmentId,
        fragmentName: inputRef.current.value,
        students: studentsToAddList.map((st) => st.id),
        doNotCount: isChecked,
      },
      {
        onSuccess: () => {
          onClose();
          setStudentsToAddList([]);
        },
      },
    );
  };

  const changeFragmentName = (event) => {
    setFragmentName(event.target.value);
  };

  const onCheckChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const confirmDelete = () => {
    if (
      window.confirm(
        "Вы действительно хотите удалить этот отрывок? Он пропадет насовсем",
      )
    ) {
      mutationDelete.mutate(fragmentId, {
        onSuccess: () => {
          refetch();
          onClose();
        },
      });
    }
  };

  return (
    <Wrapper>
      <Container>
        <Header>
          <h1>Редактирование отрывка</h1>
          <CloseButton onClick={onClose}>
            <AiFillCloseCircle />
          </CloseButton>
        </Header>
        <Body>
          <div>
            <InputContainer>
              <input
                ref={inputRef}
                placeholder="Название отрывка..."
                value={fragmentName}
                onChange={changeFragmentName}
              />
              {studentsToAddList.length > 0 && (
                <Button onClick={updateFragment}>Сохранить</Button>
              )}
            </InputContainer>
            <Checkbox
              style={{ margin: "8px 0" }}
              onChange={onCheckChange}
              checked={isChecked}
            >
              Не учитывать при генерации?
            </Checkbox>
            <div>
              <h1 style={{ margin: "16px 0 8px 0" }}>Студенты:</h1>
              <StudentsListContainer>
                {studentsToAddList.map((student) => (
                  <Student {...student} removeStudent={removeStudent} />
                ))}
              </StudentsListContainer>
              <AddStudentComponent
                addUser={addUserToList}
                selectedUsers={studentsToAddList}
              />
              <Button
                onClick={confirmDelete}
                variant="red"
                style={{ width: "100%", marginTop: "8px" }}
              >
                Удалить отрывок
              </Button>
            </div>
          </div>
        </Body>
      </Container>
    </Wrapper>
  );
};
