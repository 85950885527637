import { useMutation, useQuery } from "react-query";
import { API_URL } from "api";

export const useGetUsers = () => {
  const fetchUsers = async () => {
    const res = await fetch(`${API_URL}/user`);
    return await res.json();
  };

  return useQuery(["getUsers"], fetchUsers);
};

export const useAddUser = () => {
  return useMutation({
    mutationFn: (userName) =>
      fetch(`${API_URL}/user`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name: userName }),
      }),
  });
};

export const useDeleteUser = () => {
  return useMutation({
    mutationFn: (id) =>
      fetch(`${API_URL}/user/${id}`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      }),
  });
};

export const useRenameUser = () => {
  return useMutation({
    mutationFn: ({ id, name }) =>
      fetch(`${API_URL}/user/${id}`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name }),
      }),
  });
};
