import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  border-bottom: 1px solid #de496e;
  padding-bottom: 20px;
`;

const ItemContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-basis: 50%;
`;

const Title = styled.h1``;

export const Auditories = () => {
  return (
    <Container>
      <ItemContainer style={{ flexBasis: "15%", minWidth: "65px" }} />
      <ItemContainer>
        <Title>413</Title>
      </ItemContainer>
      <ItemContainer>
        <Title>Мастерская</Title>
      </ItemContainer>
    </Container>
  );
};
