import { Users } from "../Users/Users";
import { Schedule } from "../Schedule/Schedule";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Fragments } from "../Fragments/Fragments";

export const Home = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Schedule />} />
        <Route path="/users" element={<Users />} />
        <Route path="/fragments" element={<Fragments />} />
      </Routes>
    </Router>
  );
};
