import { QueryClient, QueryClientProvider } from "react-query";
import { Home } from "./Pages/Home/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer />
      <Home />
    </QueryClientProvider>
  );
}

export default App;
