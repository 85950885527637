import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  > div:not(:last-child) {
    border-right: 1px solid rgba(222, 73, 110, 0.4);
  }
`;

const ItemContainer = styled.div`
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  padding: 8px;
  justify-content: center;

  h1 {
    font-size: 16px;
  }
`;

const Participants = styled.div`
  margin-top: 8px;
  color: #94a3b8;
  font-size: 14px;
`;

const FilledFragment = ({ fragment, setOpenModal }) => {
  const openModal = () => {
    setOpenModal({ fragment });
  };

  return (
    <ItemContainer onClick={openModal}>
      <h1>{fragment?.name}</h1>
      <Participants>
        {fragment?.students?.map((st) => st.name)?.join(", ")}
      </Participants>
    </ItemContainer>
  );
};

const TimeContainer = styled(ItemContainer)`
  flex-basis: 65px;
  min-width: 65px;
`;

const EmptyItemContainer = styled(ItemContainer)`
  color: rgba(0, 0, 0, 0.3);
`;

const isObjectEmpty = (objectName) => {
  if (objectName === undefined) return true;
  return (
    Object.keys(objectName).length === 0 && objectName.constructor === Object
  );
};

export const Fragments = ({
  time,
  auditories,
  setOpenModal,
  setOpenPlaceNewFragmentModal,
}) => {
  return (
    <Container>
      <TimeContainer>
        <p>{time}:00</p>
      </TimeContainer>
      {auditories.map((auditory) =>
        isObjectEmpty(auditory.hours[time]) ? (
          <EmptyItemContainer
            onClick={() =>
              setOpenPlaceNewFragmentModal({ auditory: auditory, time: time })
            }
          >
            Не занято
          </EmptyItemContainer>
        ) : (
          <FilledFragment
            fragment={auditory.hours[time]}
            setOpenModal={(data) =>
              setOpenModal({ ...data, auditory: auditory, time: time })
            }
          />
        ),
      )}
    </Container>
  );
};
