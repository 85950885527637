import styled from "styled-components";
import { AiFillCloseCircle } from "react-icons/ai";
import { Button } from "../../../components/Button";
import React, { useState } from "react";
import { Select } from "antd";
import {
  useAddFragmentToEmptySpace,
  useGetFragments,
} from "../../../api/fragments";
import { useSchedule } from "../../../api/schedule";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  &.break-class-name {
    //your styles
  }
`;

const Container = styled.div`
  padding: 20px;
  background: white;
  border-radius: 16px;
  height: fit-content;
  width: fit-content;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const CloseButton = styled.button`
  padding: 0;
  border: none;
  outline: none;
  background: none;
  width: 32px;
  height: 32px;
  position: relative;
  right: -15px;
  top: -15px;

  > svg {
    width: 32px;
    height: 32px;
  }
`;

export const AddNewFragmentModal = ({
  newFragmentPlaceData,
  setNewFragmentPlaceData,
}) => {
  const [selectedFragment, setSelectedFragment] = useState(null);

  const updateFragment = (fragment) => {
    setSelectedFragment(fragment);
  };

  const mutation = useAddFragmentToEmptySpace();

  const { refetch: refetchScheduleData } = useSchedule();

  const onClose = () => {
    setNewFragmentPlaceData(null);
    setSelectedFragment(null);
  };

  const addFragment = () => {
    const data = {
      id: selectedFragment,
      dayTo: newFragmentPlaceData.foundDay.name,
      auditoryTo: newFragmentPlaceData.auditory.name,
      timeTo: newFragmentPlaceData.time,
    };
    mutation.mutate(data, {
      onSuccess: () => {
        setTimeout(() => {
          onClose();
          refetchScheduleData();
        }, 1000);
      },
    });
  };

  const { data } = useGetFragments();

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  if (!newFragmentPlaceData) return null;
  return (
    <Wrapper>
      <Container>
        <Header>
          <h1>Добавить фрагмент</h1>
          <CloseButton onClick={onClose}>
            <AiFillCloseCircle />
          </CloseButton>
        </Header>
        <Body>
          <p>
            <br />
            {`День: ${newFragmentPlaceData.foundDay.name}`}
            <br />
            {`Аудитория: ${newFragmentPlaceData.auditory.name}`}
            <br />
            {`Время: ${newFragmentPlaceData.time}:00`}
          </p>
          <Select
            showSearch
            options={data?.map((fr) => ({ value: fr.id, label: fr.name }))}
            onChange={updateFragment}
            placeholder="Выберите фрагмент"
            filterOption={filterOption}
          />
          {selectedFragment && (
            <Button onClick={addFragment} variant="green">
              Добавить
            </Button>
          )}
        </Body>
      </Container>
    </Wrapper>
  );
  // return (
  //     <Wrapper>
  //         <Container>
  //             <Header>
  //                 <h1>Настройки записи</h1>
  //                 <CloseButton onClick={() => setFragmentData(null)}>
  //                     <AiFillCloseCircle />
  //                 </CloseButton>
  //             </Header>
  //             <Body>
  //                 <InnerBox style={{ marginTop: "24px" }}>
  //                     <Title>Название</Title>
  //                     <h1>{fragmentData.fragment.name}</h1>
  //                 </InnerBox>
  //                 <InnerBox style={{ marginTop: "24px" }}>
  //                     <Title>Участники</Title>
  //                     {fragmentData.fragment.students
  //                         .map((st) => st.name)
  //                         .map((student) => (
  //                             <h1>{student}</h1>
  //                         ))}
  //                 </InnerBox>
  //             </Body>
  //             <Buttons>
  //                 {isMoving ? (
  //                     <ChoseWhereMoving
  //                         setIsMoving={setIsMoving}
  //                         fragmentData={fragmentData}
  //                     />
  //                 ) : (
  //                     <Button onClick={() => setIsMoving(true)}>
  //                         Переместить запись
  //                     </Button>
  //                 )}
  //                 <Button variant="red" onClick={confirmDelete}>
  //                     Убрать отрывок
  //                 </Button>
  //             </Buttons>
  //         </Container>
  //     </Wrapper>
  // );
};
