import React, { useState } from "react";
import { WeekItem } from "./parts/WeekItem";
import styled from "styled-components";
import { Auditories } from "./parts/Auditories";
import { Fragments } from "./parts/Fragments";
import { FragmentModal } from "./parts/FragmentModal";
import { AiOutlineUser } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { CgNotes } from "react-icons/cg";
import { useGenerateSchedule, useSchedule } from "../../api/schedule";
import { RotatingLines } from "react-loader-spinner";
import { BiRecycle } from "react-icons/bi";
import { checkIsAdmin } from "../../utils/checkIsAdmin";
import { AddNewFragmentModal } from "./parts/AddNewFragmentModal";
import { toast } from "react-toastify";

const Wrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column-reverse;
  height: 100dvh;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const daysOfWeek = [
  "Воскресенье",
  "Понедельник",
  "Вторник",
  "Среда",
  "Четверг",
  "Пятница",
  "Суббота",
];

const daysOfWeekShort = ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"];

const today = new Date();

const getCurrentWeek = () => {
  const currentWeek = [];
  let todayIndex = today.getDay() === 0 ? 6 : today.getDay() - 1; // Adjust for Sunday
  if (todayIndex === 6) todayIndex = -1;

  for (let i = 0; i < 6; i++) {
    const currentDate = new Date(today);
    currentDate.setDate(today.getDate() + i - todayIndex); // Adjust the date based on the current day
    const day = daysOfWeek[currentDate.getDay()];
    const shortDay = daysOfWeekShort[currentDate.getDay()];
    // const date = `${currentDate.getDate()}.${currentDate.getMonth() + 1}.${currentDate.getFullYear()}`;
    const date = `${currentDate.getDate()}`;
    const isToday = i - todayIndex === 0;

    currentWeek.push({ day, date, isToday, shortDay });
  }

  return currentWeek;
};

const FragmentsList = styled.div`
  max-height: calc(100dvh - 210px);
  overflow: auto;

  > div:not(:last-child) {
    border-bottom: 1px solid rgba(222, 73, 110, 0.4);
  }
`;

const ControllerContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  align-items: center;
  width: 100%;
  gap: 10px;

  > div:first-child {
    width: 100%;
  }
`;

const Controllers = styled.div`
  display: flex;
  gap: 20px;
`;

const Controller = styled.div`
  background-color: #fff0f0;
  padding: 8px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: 24px;
    height: 24px;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100dvh;
`;

export const Schedule = ({ setIsShowUsers }) => {
  const currentWeek = getCurrentWeek();

  const [selectedDate, setSelectedDate] = useState(
    currentWeek.find((day) => day.isToday)?.date ?? currentWeek[0].date,
  );
  const selectedDay = currentWeek.find(
    (weekDay) => weekDay.date === selectedDate,
  ).day;

  const { data: scheduleData, isLoading, refetch } = useSchedule();

  const [fragmentData, setFragmentData] = useState(null);
  const [newFragmentPlaceData, setNewFragmentPlaceData] = useState(null);
  const generateScheduleMutation = useGenerateSchedule();
  const navigate = useNavigate();
  const location = useLocation();

  if (
    window.location.href.includes("localhost") &&
    process.env.REACT_APP_BACKEND_URL.includes("backend.schedule")
  )
    toast("ТЫ НА ПРОДЕ");

  if (location.search === "?isAdmin=true") {
    localStorage.setItem("isAdmin", "true");
  }

  if (isLoading)
    return (
      <LoaderContainer>
        <RotatingLines
          visible={true}
          height="96"
          width="96"
          color="grey"
          strokeWidth="5"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
        />
      </LoaderContainer>
    );

  if (!scheduleData) {
    navigate("/users");
    return null;
  }

  const foundDay = scheduleData.find((days) => days.name === selectedDay);

  const setOpenModal = (data) => {
    setFragmentData(data);
  };

  const setOpenPlaceNewFragmentModal = (data) => {
    setNewFragmentPlaceData(data);
  };

  const confirmRegenerate = () => {
    if (
      window.confirm(
        "ТЫ УВЕРЕН? ВСЁ РАСПИСАНИЕ БУДЕТ СГЕНЕРИРОВАНО ЗАНОВО, СТАРОЕ ПРОПАДЕТ!!!",
      )
    ) {
      generateScheduleMutation.mutate(null, { onSuccess: () => refetch() });
    }
  };

  const isAdmin = checkIsAdmin();

  return (
    <>
      {isAdmin && (
        <>
          <FragmentModal
            fragmentData={fragmentData}
            setFragmentData={setFragmentData}
          />
          <AddNewFragmentModal
            newFragmentPlaceData={newFragmentPlaceData}
            setNewFragmentPlaceData={setNewFragmentPlaceData}
          />
        </>
      )}
      <Wrapper>
        <ControllerContent>
          <Container>
            {currentWeek?.map((weekDay) => (
              <WeekItem
                isSelected={weekDay.date === selectedDate}
                setSelectedDate={setSelectedDate}
                {...weekDay}
              />
            ))}
          </Container>
          {isAdmin && (
            <Controllers>
              <Controller onClick={() => navigate("/users")}>
                <AiOutlineUser />
              </Controller>
              <Controller onClick={() => navigate("/fragments")}>
                <CgNotes />
              </Controller>
              <Controller onClick={confirmRegenerate}>
                <BiRecycle />
              </Controller>
            </Controllers>
          )}
        </ControllerContent>
        <div>
          <Auditories />
          <FragmentsList>
            {[15, 16, 17, 18, 19, 20].map((time) => (
              <Fragments
                time={time}
                {...foundDay}
                setOpenModal={(data) =>
                  setOpenModal({ ...data, foundDay: foundDay })
                }
                setOpenPlaceNewFragmentModal={(data) =>
                  setOpenPlaceNewFragmentModal({ ...data, foundDay: foundDay })
                }
              />
            ))}
          </FragmentsList>
        </div>
      </Wrapper>
    </>
  );
};
