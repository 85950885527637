import { useMutation, useQuery } from "react-query";
import { API_URL } from "api";

export const useGetFragments = () => {
  const fetchFn = async () => {
    const res = await fetch(`${API_URL}/fragment`);
    return await res.json();
  };

  return useQuery(["getFragments"], fetchFn);
};

export const useAddFragment = () => {
  return useMutation({
    mutationFn: ({ fragmentName, students }) =>
      fetch(`${API_URL}/fragment`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name: fragmentName, students }),
      }),
  });
};

export const useDeleteFragment = () => {
  return useMutation({
    mutationFn: (id) =>
      fetch(`${API_URL}/fragment/${id}`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      }),
  });
};

export const useUpdateFragment = () => {
  return useMutation({
    mutationFn: ({ id, fragmentName, students, doNotCount }) =>
      fetch(`${API_URL}/fragment/${id}`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name: fragmentName, students, doNotCount }),
      }),
  });
};

export const useMoveFragment = () => {
  return useMutation({
    mutationFn: (data) =>
      fetch(`${API_URL}/fragment/move`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }),
  });
};

export const useAddFragmentToEmptySpace = () => {
  return useMutation({
    mutationFn: (data) =>
      fetch(`${API_URL}/fragment/add`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }),
  });
};
export const useRemoveFragment = () => {
  return useMutation({
    mutationFn: (data) =>
      fetch(`${API_URL}/fragment/remove`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }),
  });
};
