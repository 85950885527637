import styled from "styled-components";

const Container = styled.div`
  padding: 15px;
  border-radius: 20px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  position: relative;

  ${({ isSelected }) =>
    isSelected
      ? `border-radius: 16px;
background: #FFF0F0; color: #DE496E;
font-size: 20px; font-weight: 700;`
      : ""}

  ${({ isToday }) =>
    isToday
      ? ` &:before {
    content: "";
    background: #de496e;
    width: 5px;
    height: 5px;
    position: absolute;
    border-radius: 100%;
    bottom: 10px;
  }`
      : ""}
`;

const Title = styled.h1`
  font-weight: 600;
`;

const Subtitle = styled.p`
  font-size: 14px;
  color: ${({ isSelected }) => (isSelected ? "#DE496E" : "#94a3b8")};
`;

export const WeekItem = ({
  day,
  date,
  isToday,
  shortDay,
  isSelected,
  setSelectedDate,
}) => {
  const onClickHandler = () => {
    setSelectedDate(date);
  };

  return (
    <Container
      isSelected={isSelected}
      isToday={isToday}
      onClick={onClickHandler}
    >
      <Title>{date}</Title>
      <Subtitle isSelected={isSelected}>{shortDay}</Subtitle>
    </Container>
  );
};
