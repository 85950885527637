import { API_URL } from "./index";
import { useMutation, useQuery } from "react-query";

export const useSchedule = () => {
  const fetchSchedule = async () => {
    const res = await fetch(`${API_URL}/schedule`);
    const data = await res.json();
    return data.weekDays;
  };

  return useQuery(["getSchedule"], fetchSchedule);
};

export const useGenerateSchedule = () => {
  return useMutation({
    mutationFn: () =>
      fetch(`${API_URL}/schedule`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }),
  });
};
