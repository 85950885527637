import styled from "styled-components";
import { AiFillCloseCircle } from "react-icons/ai";
import { Button } from "../../../components/Button";
import { useState } from "react";
import { ChoseWhereMoving } from "./ChoseWhereMoving";
import { useSchedule } from "../../../api/schedule";
import { useRemoveFragment } from "../../../api/fragments";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  &.break-class-name {
    //your styles
  }
`;

const Container = styled.div`
  padding: 20px;
  background: white;
  border-radius: 16px;
  height: fit-content;
  width: fit-content;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Body = styled.div``;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

const InnerBox = styled.div``;

const Title = styled.div`
  color: #1e293b;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
`;

const CloseButton = styled.button`
  padding: 0;
  border: none;
  outline: none;
  background: none;
  width: 32px;
  height: 32px;
  position: relative;
  right: -15px;
  top: -15px;

  > svg {
    width: 32px;
    height: 32px;
  }
`;

export const FragmentModal = ({ fragmentData, setFragmentData }) => {
  const [isMoving, setIsMoving] = useState(false);
  const { refetch } = useSchedule();

  const mutationRemove = useRemoveFragment();

  const confirmDelete = () => {
    if (
      window.confirm(
        "Данное действие не удалит отрывок насовсем, оно только уберет его с этой конкретной позиции",
      )
    ) {
      const data = {
        id: fragmentData.fragment.id,
        dayFrom: fragmentData.foundDay.name,
        auditoryFrom: fragmentData.auditory.name,
        timeFrom: fragmentData.time,
      };
      mutationRemove.mutate(data, {
        onSuccess: () => {
          setTimeout(() => {
            refetch();
            setFragmentData(null);
          }, 1000);
        },
      });
    }
  };

  if (!fragmentData) return null;
  return (
    <Wrapper>
      <Container>
        <Header>
          <h1>Настройки записи</h1>
          <CloseButton onClick={() => setFragmentData(null)}>
            <AiFillCloseCircle />
          </CloseButton>
        </Header>
        <Body>
          <InnerBox style={{ marginTop: "24px" }}>
            <Title>Название</Title>
            <h1>{fragmentData.fragment.name}</h1>
          </InnerBox>
          <InnerBox style={{ marginTop: "24px" }}>
            <Title>Участники</Title>
            {fragmentData.fragment.students
              .map((st) => st.name)
              .map((student) => (
                <h1>{student}</h1>
              ))}
          </InnerBox>
        </Body>
        <Buttons>
          {isMoving ? (
            <ChoseWhereMoving
              setIsMoving={setIsMoving}
              fragmentData={fragmentData}
            />
          ) : (
            <Button onClick={() => setIsMoving(true)}>
              Переместить запись
            </Button>
          )}
          <Button variant="red" onClick={confirmDelete}>
            Убрать отрывок
          </Button>
        </Buttons>
      </Container>
    </Wrapper>
  );
};
