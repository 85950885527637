import styled from "styled-components";
import { EyeInvisibleOutlined } from "@ant-design/icons";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.3);
`;

const Flex = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const Fragment = ({ id, name, students, setFragmentId, doNotCount }) => {
  return (
    <Container onClick={() => setFragmentId(id)}>
      <Flex>
        {doNotCount && <EyeInvisibleOutlined />}
        <h1>{name}</h1>
      </Flex>
      <p>{students.length}</p>
    </Container>
  );
};
