import styled from "styled-components";

const variants = {
  red: "red",
  pink: "#DE496E",
  blue: "#8572FF",
  green: "#12b230",
};

export const Button = styled.button`
  outline: none;
  border: none;
  border-radius: 29px;
  padding: 15px;
  background: ${({ variant = "pink" }) => variants[variant]};
  color: white;
`;
